export const boardPositionsArray = [
    [0, 0.2822, 6.764],
    [0, 0.4, 5.4108], // Moved to LR
    [0, 0.519877, 4.0579],
    [-1.184, 0.67217, 3.3818],
    [-1.1825, 0.54526, 2.0292],
    [0, 0.64971, 1.3526], // Moved to Dallas
    [1.1837, 0.76605, 2.0292], // Museum Arts
    [2.36544, 0.67217, 2.7053],
    [3.5498, 0.74253, 2.0292],
    [4.7316, 0.1911, 1.3526],
    [4.7316, 0.55553, 0], // Yellow 7
    [4.7316, 0.49471, -1.3528],
    [3.5498, 0.55553, -2.0289],
    [2.3655, 0.81404, -2.7055], // One River
    [1.18368, 0.64165, -2.0289],
    [0, 0.5684, -1.3528],
    [-1.18248, 0.46753, -0.67624],
    [-2.3668, 0.5345, 0], // HCK2
    [-3.5486, 0.61658, -0.67623],
    [-4.7328, 0.51828, -1.3528],
    [-4.7329, 0.60396, -2.7055], // SI / Lapero
    [-3.5486, 0.51413, -3.3816],
    [-2.3668, 0.45462, -2.7055],
    [-1.1825, 0.54278, -3.3816],
    [0, 0.6721, -4.0582],
    [0, 0.62196, -5.4109],
    [0, 0.81445, -6.7636], // ???
]