export const projectLinks = [
    null,
    null,
    null,
    null,
    null,
    null,
    'https://daviddylancarr.com/code/ogallala/',
    'https://daviddylancarr.com/project/dji-shell-design-competition/',
    'https://daviddylancarr.com/project/history-of-consoles/',
    null,
    'https://daviddylancarr.com/project/seaglass-wine-co/',
    'https://daviddylancarr.com/project/the-apothecary/',
    null,
    'https://daviddylancarr.com/project/caspers-curios-the-floating-wizard-tower/',
    'https://daviddylancarr.com/project/roses-gem-weapons/',
    'https://daviddylancarr.com/project/dwarf-in-the-flask/',
    null,
    'https://daviddylancarr.com/project/hck2-partners/',
    'https://daviddylancarr.com/project/ability-connection/',
    null,
    'https://daviddylancarr.com/project/bloom-water-bath/',
    'https://daviddylancarr.com/project/loretta-lynn-sites/',
    'https://daviddylancarr.com/project/squirdle-fun/',
    'https://daviddylancarr.com/project/lapero-codery/',
    'https://daviddylancarr.com/project/three-js-theme-park/',
    null,
]