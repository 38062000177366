export const diceFaces = {
    d4: [
        [0, 0.35, 0],     // 1
        [0.32, -0.17, 0.19], // 2
        [0, -0.17, -0.37],  // 3
        [-0.32, -0.17, 0.19]  // 4
    ],
    d6: [
        [0.25, 0, 0],  // 1
        [0, 0, -0.25], // 2
        [0, 0.25, 0],  // 3
        [0, -0.25, 0], // 4
        [0, 0, 0.25],  // 5
        [-0.25, 0, 0]  //  6
    ],
    d8: [
        [-0.11, 0.11, -0.11], // 1
        [0.11, -0.11, -0.11], // 2
        [0.11, 0.11, -0.11], // 3
        [-0.11, -0.11, -0.11], // 4
        [0.11, 0.11, 0.11], // 5
        [-0.11, -0.11, 0.11], // 6
        [-0.11, 0.11, 0.11], // 7
        [0.11, -0.11, 0.11] // 8
    ],
    d10: [
        [0, 0.09, 0.2], // 1
        [-0.2, -0.09, -0.06], // 2
        [0.12, 0.09, -0.17], // 3
        [0.12, -0.09, 0.17], // 4
        [-0.12, 0.09, -0.17], // 5
        [-0.12, -0.09, 0.17], // 6
        [0.2, 0.09, 0.06], // 7
        [0, -0.09, -0.2], // 8
        [-0.2, 0.09, 0.06], // 9
        [0.2, -0.09, -0.06] // 10
    ],
    d12: [
        [0.14, -0.23, 0], // 1
        [-0.14, -0.23, 0], // 2
        [0, -0.14, -0.22], // 3
        [0, -0.14, 0.23], // 4
        [0.23, 0, -0.14], // 5
        [0.22, 0, 0.14], // 6
        [-0.23, 0, -0.14], // 7
        [-0.22, 0, 0.14], // 8
        [0, 0.14, -0.23], // 9
        [0, 0.14, 0.23], // 10
        [0.14, 0.23, 0], // 11
        [-0.14, 0.22, 0] // 12
    ],
    d20: [
        [0.17, 0.17, 0.17], // 1
        [-0.1, 0, -0.27], // 2
        [0.177, -0.17, 0.17], // 3
        [-0.27, 0.1, 0], // 4
        [0, 0.27, -0.1], // 5
        [-0.1, 0, 0.27], // 6
        [0.27, 0.1, 0], // 7
        [0, -0.27, -0.1], // 8
        [-0.17, -0.17, 0.17], // 9
        [0.17, -0.17, -0.17], // 10
        [-0.17, 0.17, 0.17], // 11
        [0.1, 0, -0.27], // 12
        [0, 0.27, 0.1], // 13
        [-0.27, -0.1, 0], // 14
        [0.17, 0.17, -0.17], // 15
        [0, -0.27, 0.1], // 16
        [0.27, -0.1, 0], // 17
        [-0.17, 0.17, -0.17], // 18
        [0.1, 0, 0.27], // 19
        [-0.17, -0.17, -0.17] // 20
    ],
    d100: [
        [0, 0.09, 0.2], // 1
        [-0.2, -0.09, -0.06], // 2
        [0.12, 0.09, -0.17], // 3
        [0.12, -0.09, 0.17], // 4
        [-0.12, 0.09, -0.17], // 5
        [-0.12, -0.09, 0.17], // 6
        [0.2, 0.09, 0.06], // 7
        [0, -0.09, -0.2], // 8
        [-0.2, 0.09, 0.06], // 9
        [0.2, -0.09, -0.06] // 10
    ],
    
}

export const colors = [
    "maroon", // 1
    "red", // 2
    "orange", // 3
    "yellow", // 4
    "lime", // 5
    "green", // 6
    "teal", // 7
    "cyan", // 8
    "blue", // 9
    "purple", // 10
    "magenta", // 11
    "pink", // 12
    "white", // 13
    "black", // 14
    "gray", // 15
    "brown", // 16
    "tan", // 17
    "olive", // 18
    "navy", // 19
    "aquamarine", // 20
]
